<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <NewsTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-row form class="form-group">
                        <v-col sm="3">Дата</v-col>
                        <v-col sm="9">{{ entity.published_at | moment('DD.MM.YYYY') }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Заголовок</v-col>
                        <v-col sm="9">{{ entity.title }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Картинка</v-col>
                        <v-col sm="9"><v-img width="300px" v-if="entity.picture" :src="imageSrc(entity.picture)" /></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Сайт</v-col>
                        <v-col sm="9">{{ entity.site ? entity.site.title : '' }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Текст для превью</v-col>
                        <v-col sm="9" v-html="htmlFormatted(entity.preview)"></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Текст</v-col>
                        <v-col sm="9"v-html="htmlFormatted(entity.text)"></v-col>
                    </v-row>

                    <v-row form class="form-group">
                        <v-col sm="3">Сортировка</v-col>
                        <v-col sm="9">{{ entity.sort }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Активен</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(entity.enabled)}`">
                                &nbsp;{{entity.enabled ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >К списку</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import NewsTabLinks from '../../components/news/NewsTabLinks';

    import { imageSrc, htmlFormatted } from '../../helpers';

    export default {
        name: 'NewsDetail',

        components: {
            NewsTabLinks,
        },

        data() {
            return {
                imageSrc,
                htmlFormatted,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('news', {
                entity: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            }
        },

        async mounted() {
            const id = this.$route.params.id;
            if(id) {
                await store.dispatch('news/get', {id});
            }
        },

        methods: {
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                this.$router.push({ path: '/news' });
            },
        },
    };
</script>