<template>
    <div class="app-tab-links">
        <template v-for="item in links">
            <v-btn
                :key="item.title"
                class="custom-pl-xs"
                :class="{ 'app-tab-links__fill_required': isFillRequired(item) }"
                :to="item.url"
                :active="isActive(item.url)"
                :disabled="isDisabled(item)"
            >
                {{ item.title }}
            </v-btn>
        </template>
    </div>
    <!-- <v-tabs 
        class="app-tab-links" 
        variant="tabs"
        mobile-breakpoint="0">
        <template v-for="item in links">
            <v-tab
                :key="item.title"
                class="custom-pl-xs"
                :to="item.url"
                :active="isActive(item.url)"
                :disabled="isDisabled(item)"
            >
                {{ item.title }}
            </v-tab>
        </template>
    </v-tabs> -->
</template>

<script>
    export default {
        props: {
            links: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            isActive(linkPath) {
                return this.$route.path.endsWith(linkPath);
            },
            isDisabled(item) {
                return item.disabled === true;
            },
            isFillRequired(item) {
                return item.fill_required === true;
            }
        },
    };
</script>
<style lang="scss" scoped>
    .app-tab-links {
        padding-bottom: 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;

        .v-btn {
            margin: 5px;
        }
        .v-btn--active {
            background: #F36223;
            color: #ffffff;
        }

        &__fill_required:not(.v-btn--active) {
            color: red;
        }
    }
</style>
